<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="form-group mt-1">
          <el-form>
            <label class="field-title-label"
              >Currency Name <span class="red-asterisk">*</span></label
            >
            <el-input
              type="text"
              v-model="field.label"
              placeholder="Enter currency name"
            ></el-input>
          </el-form>
        </div>
      </el-col>

      <el-col :span="24">
        <div class="form-group mt-1 mb-2">
          <el-form>
            <label class="field-title-label"
              >Currency Type <span class="red-asterisk">*</span></label
            >
            <br />
            <el-select
              v-model="field.default_value"
              clearable
              placeholder="Select Currency Type"
            >
              <el-option
                v-for="currency in CurrencyTypes"
                :key="currency.value"
                :label="`${currency.name} (${currency.value})`"
                :value="currency.value"
              ></el-option>
            </el-select>
          </el-form>
        </div>
      </el-col>
      <el-col :span="10">
        <div class="icon-dropdown-selection">
          <el-button type="text" @click="iconPickerdialogVisible = true">
            <div class="d-flex align-center menu-icon">
              <span>
                <icons :iconName="templateIconPath"></icons>
              </span>
              <i class="el-icon-circle-plus"></i>
            </div>
          </el-button>
        </div>
      </el-col>
    </el-row>
    <dialog-component
      :title="'Select An Icon'"
      :visible="iconPickerdialogVisible"
      :containerWidth="getIsMobile ? '100%' : '77%'"
      @before-close="closeSelectIcon"
      :isShowFooter="false"
      :selectedIcon="field.icon_name"
    >
      <div class="icons-component">
        <div class="iconslistScrollable">
          <icons-list @icon-data="getIconPath"></icons-list>
        </div>
      </div>
    </dialog-component>
  </div>
</template>

<script>
export default {
  name: "templates-formComponents-CurrencyTypes",
  props: ["field", "fieldsData"],
  data() {
    return {
      iconPickerdialogVisible: false,
      CurrencyTypes: [
        { value: "USD", name: "$" },
        { value: "CAD", name: "$" },
        { value: "AUD", name: "$" },
        { value: "INR", name: "₹" },
        { value: "EUR", name: "€" },
        { value: "MXN", name: "$" },
        { value: "ZAR", name: "R" },
        { value: "MYR", name: "RM" },
        { value: "GBP", name: "£" },
      ],
    };
  },
  methods: {
    closeSelectIcon() {
      this.iconPickerdialogVisible = false;
    },
    getIconPath(icon) {
      this.templateIconPath = icon;
      this.$set(this.field, "icon_name", icon);
      this.iconPickerdialogVisible = false;
    },
  },
};
</script>

<style lang="scss">
.mgtop {
  margin-top: 10px;
}
.red-asterisk {
  color: red;
}
</style>
